/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react";
import { navigate } from "gatsby";

export const onInitialClientRender = () => {
    setTimeout(function() {
        document.getElementById("___loader").style.display = "none"
    }, 1000)
}

export const onRouteUpdate = ({ location }) => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100); // Small delay ensures DOM updates before scrolling
    }
  };
  